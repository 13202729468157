import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/HomeWrapper";
import AboutSection from "./components/Sections/AboutSection";
import Applications from "./components/Sections/Works/Project";
import Experience from "./components/Sections/Works/Experience";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
import UserInterface from "./components/Sections/Works/UserInterface";
import Graphic from "./components/Sections/Works/Graphic";
import CatefyUI from "./pages/CatefyUI";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/CatefyUI" element={<CatefyUI />} />
          <Route path="/Graphic" element={<Graphic />} />
          <Route path="/UserInterface" element={<UserInterface />} />
          <Route path="/Project" element={<Applications />} />
          <Route path="/Experience" element={<Experience />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Skills" element={<Skills />} />
          <Route path="/AboutSection" element={<AboutSection />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

import React from "react";
import Footer from "../../Footer";
import Navbar from "../../Navbar";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import experiencesData from "../../../data/experienceData";

const Experience = () => {
  return (
    <div className="bg-white">
      <Navbar />
      <div className="container mx-auto pt-20">
        <div className="sm:px-48">
          <VerticalTimeline lineColor="gray">
            {experiencesData.map((item, index) => (
              <React.Fragment key={index}>
                <VerticalTimelineElement
                  contentStyle={{
                    boxShadow: "none",
                    border: "1px solid rgba(0, 0, 0, 0.05)",
                    textAlign: "left",
                    padding: "1.3rem 2rem",
                  }}
                  contentArrowStyle={{
                    borderRight: "7px solid  rgb(33, 150, 243)",
                  }}
                  iconStyle={{
                    backgroundColor: "white",
                    border: "0",
                  }}
                  dateClassName="!text-gray-500"
                  date={item.date}
                >
                  <h3 className="font-semibold !text-gray-500 capitalize">
                    {item.title}
                  </h3>
                  <p className="font-normal !text-[12px] !mt-0">
                    {item.company}
                  </p>
                  <p className="font-normal !text-[12px] !mt-0">
                    {item.location}
                  </p>
                  <p className="!mt-1 !font-normal !text-gray-500 dark:text-white/75">
                    {item.description}
                  </p>
                </VerticalTimelineElement>
              </React.Fragment>
            ))}
          </VerticalTimeline>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Experience;

import React from "react";
import data from "../data/data";
import designdata from "../data/designData";
import LetterAnimation from "./LetterAnimation";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { motion } from "framer-motion";

const fadeInAnimationVariants = {
  initial: {
    opacity: 0,
    y: 100,
  },
  animate: (index) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.4 * index,
    },
  }),
  hover: {
    scale: 1.1,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  },
};

const Skills = () => {
  const textToAnimate1 = "Web Development Tools";
  const textToAnimate2 = "Web and UI/UX Design Tools";
  return (
    <div>
      <Navbar />
      <div className="min-h-screen bg-white">
        <div className="container mx-auto pt-28">
          <h1 className="text-3xl text-center font-bold text-zinc-700 pb-5">
            <LetterAnimation text={textToAnimate1} />
          </h1>
          <ul className="flex flex-wrap justify-center gap-2 text-lg text-gray-700 sm:mx-[50px] lg:mx-[140px] xl:mx-[300px]">
            {data.map((skill, index) => (
              <motion.li
                className="box borderBlack rounded-xl px-5 py-3 cursor-pointer"
                key={index}
                variants={fadeInAnimationVariants}
                initial="initial"
                whileInView="animate"
                whileHover="hover"
                viewport={{
                  once: true,
                }}
                custom={index}
                style={{
                  background: `hsl(${index * 30}, 70%, 80%)`, // Generate dynamic background color
                }}
              >
                {skill}
              </motion.li>
            ))}
          </ul>
        </div>
        <div className="container mx-auto pt-10 sm:pt-20">
          <h1 className="text-3xl font-bold text-center text-zinc-700 pb-5">
            <LetterAnimation text={textToAnimate2} />
          </h1>
          <ul className="flex flex-wrap justify-center gap-2 text-lg text-gray-800 mx-[20px] sm:mx-[70px] lg:mx-[200px] xl:mx-[400px]">
            {designdata.map((design, index) => (
              <motion.li
                className="box borderBlack rounded-xl px-5 py-3 cursor-pointer"
                key={index}
                variants={fadeInAnimationVariants}
                initial="initial"
                whileInView="animate"
                whileHover="hover"
                viewport={{
                  once: true,
                }}
                custom={index}
                style={{
                  background: `hsl(${index * 30}, 70%, 80%)`,
                }}
              >
                {design}
              </motion.li>
            ))}
          </ul>
        </div>
      </div>
      <div className="bg-white pt-10">
        <Footer />
      </div>
    </div>
  );
};

export default Skills;

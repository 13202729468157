import Portimg from "../img/portimg.png";

const graphicData = [
  {
    title: "Design Portfolio",
    description:
      "You can explore my graphic design portfolio website to see my design works.",
    tags: ["Figma", "Pexels", "Adobe XD", "Adobe Illustrator"],
    imageUrl: Portimg,
    link: "https://marvenparay-portfolio.vercel.app/",
  },
];

export default graphicData;

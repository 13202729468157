import React from "react";
import { motion } from "framer-motion";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import userinterfaceProject from "../../../data/userinterfaceData";

const fadeInAnimationVariants = {
  initial: {
    opacity: 0,
    y: 10,
  },
  animate: (index) => ({
    opacity: 5,
    x: 0,
    transition: {
      delay: 0.3 * index,
    },
  }),
  boxhover: {
    scale: 1.1,
  },
  hover: {
    scale: 1.1,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  },
};

const UserInterface = () => {
  return (
    <div className="bg-white">
      <Navbar />
      <div className="container mx-auto pt-24 pb-10">
        <div className="px-5 md:pl-[50px] lg:pl-[180px] xl:pl-[310px] xxl:pl-[429px]">
          {userinterfaceProject.map((project, index) => (
            <motion.div
              key={index}
              custom={index}
              variants={fadeInAnimationVariants}
              initial="initial"
              whileInView="animate"
              whileHover="boxhover"
              viewport={{
                once: true,
              }}
              className="group mb-3 sm:mb-8 last:mb-0"
            >
              <section className="max-w-[42rem] border border-black/5 rounded-lg overflow-hidden sm:pr-8 relative sm:h-[24rem] hover:bg-gray-200 transition sm:group-even:pl-8 dark:text-white dark:bg-white/10 dark:hover:bg-white/20">
                <div className="pt-4 pb-7 px-5 sm:pl-10 sm:pr-2 sm:pt-10 sm:max-w-[50%] flex flex-col h-full sm:group-even:ml-[18rem]">
                  <div className="text-gray-800">
                    <h3 className="text-2xl font-semibold">{project.title}</h3>
                    <p className="mt-2 leading-relaxed">
                      {project.description}
                    </p>
                  </div>
                  <ul
                    className={`flex flex-wrap mt-4 gap-2  ${
                      index === 1 ? "pt-3" : ""
                    }`}
                  >
                    {project.tags.map((tag, index) => (
                      <motion.li
                        className="bg-white px-3 py-1 text-[0.7rem] uppercase tracking-wide text-white cursor-pointer font-medium rounded-full"
                        key={index}
                        variants={fadeInAnimationVariants}
                        whileHover="hover"
                        viewport={{
                          once: true,
                        }}
                        custom={index}
                        style={{
                          background: `hsl(${index * 30}, 70%, 60%)`, // Generate dynamic background color
                        }}
                      >
                        {tag}
                      </motion.li>
                    ))}
                  </ul>
                  <div className="flex gap-3 sm:mt-auto">
                    {project.link && (
                      <motion.a
                        className="flex rounded-full bg-cyan-800 py-1 px-2 text-white hover:bg-sky-600 cursor-pointer"
                        key={project.link}
                        variants={fadeInAnimationVariants}
                        whileHover="hover"
                        viewport={{
                          once: true,
                        }}
                        custom={project.link}
                        style={{
                          background: `hsl(${project.link * 30}, 70%, 60%)`, // Generate dynamic background color
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        <a href={project.link}>View</a>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 ml-0 text-white transition"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                          />
                        </svg>
                      </motion.a>
                    )}
                    {/* {project.route && (
                      <motion.a
                        className="flex rounded-full text-sm bg-cyan-800 py-1 px-2 text-white hover:bg-sky-600 cursor-pointer"
                        key={project.route}
                        variants={fadeInAnimationVariants}
                        whileHover="hover"
                        viewport={{
                          once: true,
                        }}
                        custom={project.route}
                        style={{
                          background: `hsl(${project.route * 30}, 70%, 60%)`, // Generate dynamic background color
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        <a href={project.route}>CaseStudy</a>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 ml-0 text-white transition"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                          />
                        </svg>
                      </motion.a>
                    )} */}
                  </div>
                </div>

                <img
                  src={project.imageUrl}
                  alt="Project I worked on"
                  quality={95}
                  className="absolute hidden sm:block top-8 -right-40 w-[28.25rem] rounded-t-lg shadow-2xl
      transition 
      group-hover:scale-[1.04]
      group-hover:-translate-x-3
      group-hover:translate-y-3
      group-hover:-rotate-2

      group-even:group-hover:translate-x-3
      group-even:group-hover:translate-y-3
      group-even:group-hover:rotate-2

      group-even:right-[initial] group-even:-left-40"
                />
              </section>
            </motion.div>
          ))}
        </div>
      </div>
      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
};

export default UserInterface;

import Catefy from "../img/Catefy.png";
import AMS from "../img/AMS.png";
import Pick from "../img/Pickadev.png";
import Gabby from "../img/Coverchatbot.png";
import Optimal from "../img/ecommerce-v3.png";

const userinterfaceData = [
  {
    title: "OptimalK9Wellness",
    description:
      "As the Lead Designer at K9 Mania Dog Training, I masterfully crafted an intuitive and visually striking e-commerce platform that seamlessly caters to the diverse needs of dog owners.",
    tags: ["Figma", "Pexels", "Canva"],
    imageUrl: Optimal,
    link: "https://optimalk9wellness.com/",
    route: "/CatefyUI",
  },
  {
    title: "Catefy",
    description:
      "Catefy is my first approved web landing page UI Design in my previous intern at wela school systems, a software company that builds LMS.",
    tags: ["Figma", "Pexels", "Adobe XD", "Adobe Illustrator"],
    imageUrl: Catefy,
    link: "https://www.catefy.com/",
    route: "/CatefyUI",
  },
  {
    title: "AMS App",
    description:
      "In my capstone project during my college years, I am the lead UI/UX designer for a collaborative team working on an innovative attendance monitoring system.",
    tags: ["Figma", "Pexels", "Adobe XD", "Adobe Illustrator"],
    imageUrl: AMS,
    link: undefined,
  },
  {
    title: "Pick.com",
    description:
      "Pick.com is my side project startup web-app that I designed and developed. It is still in production and will be launched soon.",
    tags: ["Figma"],
    imageUrl: Pick,
    link: undefined,
  },
  {
    title: "Gabby",
    description:
      "In my freelance role, I undertook a dynamic project to develop user interface for a chatbot named Gabby. ",
    tags: ["figma"],
    imageUrl: Gabby,
    link: undefined,
  },
];

export default userinterfaceData;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { motion } from "framer-motion";
import dataFooter from "../data/dataFooter";

const fadeInAnimationVariants = {
  initial: {
    opacity: 0,
    y: 10,
  },
  animate: (index) => ({
    opacity: 5,
    x: 0,
    transition: {
      delay: 0.3 * index,
    },
  }),
  boxhover: {
    scale: 1.1,
  },
  hover: {
    scale: 1.1,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  },
};

const Footer = () => {
  return (
    <div className="box container mx-auto lg:px-[140px] xl:px-[270px] xxl:px-[400px]">
      <footer className="footer p-4 text-neutral-content">
        <aside className="items-center grid-flow-col"></aside>
        <nav className="grid-flow-col gap-4 md:place-self-end md:justify-self-end">
          {dataFooter.map((footerdata) => (
            <a href={footerdata.link}>
              <motion.div
                className="rounded-lg"
                variants={fadeInAnimationVariants}
                whileHover="hover"
                viewport={{
                  once: true,
                }}
              >
                {footerdata.svgImage}
              </motion.div>
            </a>
          ))}
        </nav>
      </footer>
    </div>
  );
};

export default Footer;

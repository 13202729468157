import React from "react";
import { motion } from "framer-motion";

const LetterAnimation = ({ text }) => {
  const defaultAnimation = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };

  return (
    <motion.span
      initial="hidden"
      animate="visible"
      transition={{ staggerChildren: 0.1 }}
      aria-hidden
    >
      {text.split("").map((char) => (
        <motion.span variants={defaultAnimation}>{char}</motion.span>
      ))}
    </motion.span>
  );
};

export default LetterAnimation;

const skills = [
  "HTML",
  "CSS",
  "JavaScript",
  "TypeScript",
  "React",
  "React Native",
  "Next.js",
  "Node.js",
  "Git",
  "Tailwind",
  "Sequelize",
  "MongoDB",
  "Redux",
  "MySQL",
  "Express",
  "PostgreSQL",
  "Daisy UI",
  "Postman",
  "Framer Motion",
  "Firebase",
];

export default skills;
